import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { API_RESOURCE_MEASUREMENT, API_RESOURCE_USERS } from 'Consts/apiResources';
import { ADMIN_MEASUREMENTS_MANAGE, ADMIN_USERS_MANAGE } from 'Consts/routes';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';
import { MEASUREMENTS_CONFIG } from 'Consts/measurementConfig';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getFullName as getUserFullName } from 'Utils/user';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminMeasurementsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            config: PropTypes.func.isRequired,
        }).isRequired,
        config: PropTypes.object,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
        config: null,
    };

    state = {
        formState: {},
        products: [],
    };

    componentDidMount = () => {
        const { data, location, predefinedState, actions } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        let overrideState = {};
        if(queryObject.userId) {
            overrideState.userId = queryObject.userId;
        }

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...overrideState,    
                ...predefinedState,                
            },
        }));

        if(predefinedState?.user?.id || queryObject.userId) {
            actions.config({ userId: queryObject.userId || predefinedState?.user?.id });
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        userId: data.user
            ? { value: data.user.id, label: getUserFullName(data.user).label }
            : null,
        submitterId: data.submitter
            ? { value: data.submitter.id, label: getUserFullName(data.submitter).label }
            : null,
        measureDate: data.measureDate && moment(data.measureDate).toDate(),
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        let tmp = {
            additionals: {},
        };

        for(let index in formState) {
            if(index.startsWith('additionals_')) {
                tmp.additionals[index.replace('additionals_', '')] = formState[index];
            } else {
                tmp[index] = formState[index];
            }
        }

        return actions.create({
            ...formState,
            ...tmp,
            userId: fromSelectObject(formState.userId),
            submitterId: fromSelectObject(formState.submitterId),
            measureDate: dateToApiFormat(formState.measureDate, 'datetime', true),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_MEASUREMENTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_MEASUREMENT].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        let tmp = {
            additionals: {},
        };

        for(let index in formState) {
            if(index.startsWith('additionals_')) {
                tmp.additionals[index.replace('additionals_', '')] = formState[index];
            } else {
                tmp[index] = formState[index];
            }
        }

        return actions.update({
            ...formState,
            ...tmp,
            id: data.id,
            userId: fromSelectObject(formState.userId),
            submitterId: fromSelectObject(formState.submitterId),
            measureDate: dateToApiFormat(formState.measureDate, 'datetime', true),
        });
    }
    
    renderMeasurementConfigInputs = data => {
        let tmp = [];

        if(data?.fieldsConfig) {
            const { fieldsConfig } = data;

            for (let index in fieldsConfig) {
                let icon = require('../../../../theme/images/icons/' + fieldsConfig[index].icon);
    
                tmp.push({
                    type: 'input',
                    name: MEASUREMENTS_CONFIG[index] ? index : 'additionals_' + index,
                    label: MEASUREMENTS_CONFIG[index] ? MEASUREMENTS_CONFIG[index] : fieldsConfig[index].name,
                    inputProps: {
                        type: 'number',
                    },
                });
            }
        }
        
        return tmp;
    }

    onStateChange = data => {
        const { actions, config } = this.props;
        const { formState } = this.state;

        if(fromSelectObject(formState.userId) !== fromSelectObject(data.userId)) {
            actions.config({ userId: fromSelectObject(data.userId) });
        }

        this.setState({
            formState: data,
        });
    };

    render() {
        const { data, location, history, actions, config } = this.props;
        const { formState } = this.state;

        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-measurements-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Identyfikator',
                        value: formState.id,
                    }, {
                        visible: Boolean(data && data.user),
                        label: 'Użytkownik',
                        value: data && data.user && getUserFullName(data.user).label,
                        to: data && data.user && withVariables(ADMIN_USERS_MANAGE.path, { id: data.user.id }),
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: this.onStateChange,
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Użytkownik',
                            isVisible: Boolean(!queryObject.userId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_ADEPT,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'submitterId',
                            label: 'Zgłaszający',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'measureDate',
                            label: 'Data pomiaru',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                            },
                        },
                        ...this.renderMeasurementConfigInputs(config?.data),
                        ],
                    }]}
                />
            </StyledComponent>
        );
    }
}

